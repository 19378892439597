<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12"><h1 class="text-primary">Edit Profile</h1></div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="firstName" class="form-label">First Name</label>
        <div class="input-group mb-3">
          <span class="input-group-text"><i class="fa fa-user input-icon-swing"></i></span>
          <input
            type="text"
            id="first_name"
            class="form-control label-require"
            placeholder="First Name"
            :value="$data.userDetails.first_name"
          />
        </div>
        <ErrorFormField :msgArray="errors.first_name" v-if="errors.first_name.length > 0" />
      </div>
      <div class="col-md-6">
        <label for="lastName" class="form-label">Last Name</label>
        <div class="input-group mb-3">
          <span class="input-group-text"><i class="fa fa-user input-icon-swing"></i></span>
          <input
            type="text"
            id="last_name"
            class="form-control"
            placeholder="Last Name"
            :value="$data.userDetails.last_name"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="DOB" class="form-label">Birthday</label>
        <div class="input-group mb-3">
          <span class="input-group-text"><i class="far fa-calendar input-icon-swing"></i></span>
          <input
            type="text"
            id="dob"
            class="form-control label-require"
            placeholder="Birthday"
            name="birthday"
            :value="$data.userDetails.dob"
          />
        </div>
        <ErrorFormField :msgArray="errors.birthday" v-if="errors.birthday.length > 0" />
      </div>
      <div class="col-md-5">
        <label class="form-label">Gender</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="gender"
            id="genderMale"
            value="1"
            :checked="$data.userDetails.gender == 1"
          />
          <label class="form-check-label" for="GenderMale">Male</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="gender"
            id="genderFemale"
            value="2"
            :checked="$data.userDetails.gender == 2"
          />
          <label class="form-check-label" for="GenderFemale">Female</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class="form-label">Languages</label>
        <select id="languages" class="form-select" name="languages" multiple>
          <option
            :value="x.name"
            v-for="x in $data.languages"
            :key="x.name"
            :selected="$data.userDetails.language.split(', ').includes(x.name)"
          >
            {{ x.name }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label class="form-label">Timezone</label>
        <select id="timezone" class="form-select" name="timezone">
          <option
            :value="'(UTC' + x.offset + ') ' + x.name"
            v-for="x in $data.timezones"
            :key="x.name"
            :selected="$data.userDetails.timezone == '(UTC' + x.offset + ') ' + x.name"
          >
            (UTC {{ x.offset }} ) {{ x.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="aboutus" class="form-label">About you</label>
          <textarea
            class="form-control"
            id="aboutus"
            rows="4"
            @input="aboutChangeHandler"
            @change="aboutChangeHandler"
            :value="$data.userDetails.aboutus"
          ></textarea>
        </div>
        <div class="col-auto text-end">
          <span id="aboutHelpText" class="form-text"> 0 / 1000</span>
        </div>
        <ErrorFormField :msgArray="$data.errors.about" v-if="$data.errors.about.length > 0" />
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="interests" class="form-label"> Your interest</label>
          <textarea
            class="form-control"
            id="interests"
            rows="4"
            @input="interestChangeHandler"
            @change="interestChangeHandler"
            :value="$data.userDetails.interests"
          ></textarea>
        </div>
        <div class="col-auto text-end">
          <span id="interestHelpText" class="form-text"> 0 / 1000</span>
        </div>
        <ErrorFormField :msgArray="$data.errors.interest" v-if="$data.errors.interest.length > 0" />
      </div>
    </div>
    <h3>Emergency contact</h3>
    <div class="row">
      <div class="col-md-6">
        <label for="" class="form-label">Full name</label>
        <input
          type="text"
          name="emergency_full_name"
          class="form-control"
          id="emergency_full_name"
          :value="$data.userDetails.emergency_full_name"
        />
      </div>
      <div class="col-md-6">
        <label for="" class="form-label">Phone number</label>
        <input
          type="text"
          name="emergency_phone"
          class="form-control"
          id="emergency_phone"
          :value="$data.userDetails.emergency_phone"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label for="" class="form-label">Email </label>
        <input
          type="text"
          name="emergency_email"
          class="form-control"
          id="emergency_email"
          :value="$data.userDetails.emergency_email"
        />
      </div>
      <div class="col-md-6">
        <label for="" class="form-label">Relationship</label>
        <input
          type="text"
          name="emergency_relationship"
          class="form-control"
          id="emergency_relationship"
          :value="$data.userDetails.emergency_relationship"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12">
        <LoadingIcons v-if="ajaxLoad.normal" />
        <button class="btn btn-primary" @click="saveHandler" v-else>Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorFormField from '../../common/error/ErrorFormField';
import LoadingIcons from '../../common/LoadingIcons.vue';

import jQuery from 'jquery';
const $ = jQuery.noConflict();
window.daterangepicker = require('daterangepicker');
window.moment = require('moment');
let maxYear = window.moment().format('YYYY');
export default {
  name: 'EditProfile',
  //eslint-disable-next-line
  components: { ErrorFormField, LoadingIcons },
  data() {
    return {
      ajaxLoad: {
        normal: false,
      },
      userDetails: {},
      status: '',
      messages: [],
      errors: {
        common: [],
        first_name: [],
        birthday: [],
        about: [],
        interest: [],
      },
      languages: [],
      timezones: [],
    };
  },
  methods: {
    aboutChangeHandler: function () {
      document.getElementById('aboutHelpText').innerHTML =
        document.getElementById('about').value.length.toString() + ' / 1000';
    },
    interestChangeHandler: function () {
      document.getElementById('interestHelpText').innerHTML =
        document.getElementById('interest').value.length.toString() + ' / 1000';
    },
    saveHandler: async function () {
      this.$data.ajaxLoad.normal = true;
      let apiUrl = process.env.VUE_APP_API_URL;
      var selectLanguages = document.getElementById('languages');
      var selectedLanguagesArray = [...selectLanguages.options]
        .filter((option) => option.selected)
        .map((option) => option.value);
      var languages = selectedLanguagesArray.join(', ');
      let data = {
        unique_id: this.$store.state.unique_id,
        first_name: document.getElementById('first_name').value,
        last_name: document.getElementById('last_name').value,
        birthday: document.getElementById('dob').value,
        gender: document.querySelector('input[name="gender"]:checked').value,
        languages: languages,
        timezone: document.getElementById('timezone').value,
        aboutus: document.getElementById('aboutus').value,
        interests: document.getElementById('interests').value,
        emergency_full_name: document.getElementById('emergency_full_name').value,
        emergency_phone: document.getElementById('emergency_phone').value,
        emergency_email: document.getElementById('emergency_email').value,
        emergency_relationship: document.getElementById('emergency_relationship').value,
      };

      console.log(data);
      // console.log('selectedLanguages', selectedLanguagesArray);
      // console.log('selectedLanguages string', selectedLanguagesArray.join(', '));
      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      // console.log('data signup ', data);
      // console.log('data signup formData ', formData);
      let token = this.$store.state.token;

      let headersObject = new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      let self = this;
      await fetch(apiUrl + 'user/update-profile', {
        method: 'POST',
        headers: headersObject,
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          let messages = resData.messages;
          if (resData.status == 'error') {
            if (messages.common.length > 0) self.$data.errors.common = messages.common;
            else self.$data.errors.common = [];

            if (messages.first_name.length > 0) self.$data.errors.first_name = messages.first_name;
            else self.$data.errors.first_name = [];

            if (messages.birthday.length > 0) self.$data.errors.birthday = messages.birthday;
            else self.$data.errors.birthday = [];

            console.log('self.$data ', self.$data);
            self.$data.ajaxLoad.normal = false;
            return false;
          }
          console.log('success update');
          self.$router.push('/profile/' + this.$store.state.unique_id);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      this.$data.ajaxLoad.normal = false;
      console.log(this.$data);
    },
  },
  async created() {
    // let tmpState = this.$store.stage;
    if (this.$store.state.token === undefined) {
      this.$router.push('/');
      return;
    }
    let unique_id = this.$store.state.unique_id;
    // console.log('unique_id: ' + unique_id);
    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;
    const formData = 'unique_id=' + encodeURIComponent(unique_id) + '&is_for_edit=true';
    let token = this.$store.state.token;

    let headersObject = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    await fetch(apiUrl + 'user/get-profile', {
      method: 'POST',
      headers: headersObject,
      body: formData,
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        self.$data.status = resData.status;

        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }

        if (resData.status == 'warning') {
          self.$data.userDetails = null;
          self.$data.messages = resData.messages;
          return;
        }

        self.$data.userDetails = resData.userDetails;
        self.$data.languages = resData.languages;
        self.$data.timezones = resData.timezones;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },

  mounted() {
    // let daterangepicker = require('daterangepicker');
    $('#languages').select2({
      placeholder: 'Select languages',
      // templateSelection: onSelectCurrencyChange,
      // templateSelection: (state) => onSelectCurrencyChange(state, self),
      // dropdownCssClass: 'test',
      selectionCssClass: 'border-0 bg-primary text-white myselect',
      // selectionCssClass: 'form-select form-select ',
      // matcher: matchCustom,
      dropdownAutoWidth: true,
      // theme: 'bootstrap',
    });
    $('input[name="birthday"]').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      minYear: 1901,
      maxYear: parseInt(maxYear, 10),
      autoApply: true,
      startDate: window.moment().subtract(18, 'years'),
      locale: {
        format: 'DD/MM/YYYY',
      },
    });
    // console.log('sigup wg', window.google);
  },

  unmounted() {
    // delete window.moment;
    // delete window.daterangepicker;
  },
};
</script>

<style scoped lang="sass">
@import '../../../../node_modules/daterangepicker/daterangepicker.css'
</style>
